* {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto; /* Adjust the height as needed */
  background-color: white;
  padding: 25px;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 600px; /* Adjust the max-width as needed */
  margin: 0 auto;
}
