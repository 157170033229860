* {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.gallery {
  display: flex; /* Add flexbox */
  justify-content: center; /* Horizontally center the items */
  align-items: center; /* Vertically center the items */
  position: relative;
  height: 350px;
  background-color: #8e8e8e;
}