/* src/styles/Admin.css */

.admin-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.review-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.review-item {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.review-item h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
}

.review-item p {
  margin: 5px 0;
  color: #666;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.actions button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.actions button:hover {
  background-color: #0056b3;
}

.no-reviews {
  text-align: center;
  color: #666;
}
