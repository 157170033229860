* {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.footer {
  position: relative;
  height: 280px;
  background-color: #414141;
}

.footer-header {
  white-space: nowrap;
}

.footer-tag {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-hours {
  position: absolute;
  top: 90px;
  right: 25%;
  transform: translate(-50%, -50%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.footer-contact {
  position: absolute;
  top: 90px;
  left: 25%;
  transform: translate(-50%, -50%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* CSS styles for extra small devices */
  .footer {
    height: 400px;
  }

  .footer-contact {
    top: 90px;
    left: 55%;
  }

  .footer-hours {
    top: 250px;
    left: 55%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* CSS styles for small devices */
  .footer {
    height: 400px;
  }

  .footer-contact {
    top: 90px;
    left: 55%;
  }

  .footer-hours {
    top: 250px;
    left: 55%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* CSS styles for medium devices */
  .footer {
    height: 280px;
  }

  .footer-contact {
    top: 90px;
    left: 25%;
  }

  .footer-hours {
    top: 90px;
    left: 80%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* CSS styles for large devices */
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* CSS styles for extra large devices */
}

/* Custom devices (wider screens, 1390px and up) */
@media only screen and (min-width: 1390px) {
  /* CSS styles for wider screens */
}
