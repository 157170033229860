* {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

html, body {
  overflow-x: hidden;
}

.header {
  position: relative;
  height: 670px;
  background-image: url('/src/images/header.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
  overflow-x: hidden;
}

.header-contact {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-link {
  display: flex;
  font-size: 20px;
  align-items: center;
  color: white;
  text-decoration: none;
}

.contact-link:hover {
  color: blue;
  cursor: pointer;
}

.separator {
  margin: 0 15px;
}

.header-logo {
  position: absolute;
  scale: 20%;
  top: -260px;
  left: -260px;
}

.header-nav {
  position: absolute;
  top: 70px;
  right: -170px;
  margin-right: 200px;
}

.header-nav-button {
  color: white;
  text-decoration: none;
  font-size: 25px;
  margin: 25px;
}

.header-nav-button:hover {
  color: red;
}

.nav-title {
  position: absolute;
  color: red;
  font-size: 70px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* CSS styles for extra small devices */
  .header {
    height: 300px;
  }

  .header-contact {
    display: none;
  }
  
  .header-logo {
    scale: 15%;
    top: -280px;
    left: -280px;
  }

  .nav-title {
    top: 1px;
    left: 65%;
    font-size: 50px;
    white-space: normal;
  }

  .header-nav {
    top: 180px;
    right: -130px;
  }

  .header-nav-button {
    font-size: 35px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* CSS styles for small devices */
  .header {
    height: 400px;
  }

  .header-contact {
    display: none;
  }
  
  .header-logo {
    scale: 15%;
    top: -280px;
    left: -280px;
  }

  .nav-title {
    top: 25px;
    left: 60%;
    font-size: 45px;
  }

  .header-nav {
    top: 50%;
    right: -180px;
  }

  .header-nav-button {
    font-size: 35px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* CSS styles for medium devices */
  .header {
    height: 475px;
  }
  
  .header-logo {
    scale: 15%;
    top: -280px;
    left: -280px;
  }

  .nav-title {
    top: 290px;
    left: 50%;
    font-size: 70px;
  }

  .header-nav {
    top: 30px;
    right: -130px;
  }

  .header-nav-button {
    font-size: 35px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* CSS styles for large devices */
  .header {
    height: 580px;
  }
  .header-contact {
    top: 30px;
    display: flex;
    font-size: 30px;
  }
  .header-nav {
    top: 55px;
    right: -180px;
  }
  .nav-title {
    top: 350px;
    font-size: 80px;
  }
  .contact-link {
    font-size: 16px;
  }
  .separator {
    font-size: 16px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* CSS styles for extra large devices */
  .header {
    height: 670px;
  }
  .header-contact {
    display: flex;
  }
  .nav-title {
    top: 250px;
    font-size: 100px;
  }
  .header-nav {
    top: 40px;
  }
}

/* Custom devices (wider screens, 1390px and up) */
@media only screen and (min-width: 1390px) {
  /* CSS styles for wider screens */
  .header {
    background-size:cover;
  }
  .nav-title {
    top: 250px;
    font-size: 140px;
  }
}