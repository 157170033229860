/* ../styles/Reviews.css */
* {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.reviews-outer-container {
  margin: 10px; /* Add 20px margin to the left and right of the entire review component */
}

.reviews-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 40px;
}

h2 {
  margin-top: 30px;
  margin-bottom: 20px;
  color: #414141;
}

.reviews-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.review {
  width: 100%;
  max-width: 400px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.review h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
}

.review p {
  margin: 5px 0;
  color: #666;
}

.form-group {
  display: flex;
  flex-direction: row; /* Change to row to align elements horizontally */
  /* align-items: center; Align elements horizontally */
  margin-bottom: 10px;
}

label {
  display: block;
  font-weight: bold;
  margin-right: 10px; /* Add spacing between label and input */
}

input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center form elements horizontally */
}

button {
  display: inline-block;
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  width: 150px;
}

button:hover {
  background-color: red;
}

.name-text-box {
  width: 200px;
}

.review-text-box {
  width: 440px;
}

.review-text-box textarea {
  height: 100px;
}