/* text */

.services-info {
  text-align: center;
  margin-bottom: 65px;
  margin-top: 0px;
}

.services-info h2 {
  font-size: 35px;
  margin-bottom: 15px;
}

.services-info ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
  font-size: 24px;
}

.services-info ul li {
  margin-bottom: 5px;
}

.services-info p {
  font-size: 25px;
  margin-bottom: 5px;
}