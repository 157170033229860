/* text */

.products-info {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 0px;
}

.products-info h2 {
  font-size: 35px;
  margin-bottom: 15px;
}

.products-info ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
  font-size: 24px;
}

.products-info ul li {
  margin-bottom: 5px;
}

.products-info p {
  font-size: 25px;
  margin-bottom: 5px;
}

/* images */

.products-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 60px 80px;
  
}

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 30px;
}

.product-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s;
}

.product-image:hover {
  transform: scale(1.3);
}



@media (max-width: 992px) {
  .products-container {
    grid-template-columns: 1fr;
  }

  .product-image {
    width: 450px;
    height: 450px;
  }
}

@media (max-width: 600px) {
  .products-container {
    grid-template-columns: 1fr;
  }

  .product-image {
    width: 300px;
    height: 300px;
  }
}